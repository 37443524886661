import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Navigation from "../components/navigation/navigation"
import Newsletter from "../components/newsletter/newsletter"
import Lists from "../components/lists/lists"
import SEO from "../components/seo/seo"
import SearchLanding from "../components/search/search-landing"

export const query = graphql`
  query SearchQuery {
    prismic {
      allNavigations {
        edges {
          node {
            subscription_error_message
            subscription_success_message
            header {
              ... on PRISMIC_NavigationHeaderMenu {
                primary {
                  title
                  type
                  link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
                fields {
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                  overview
                }
              }
            }
            footer {
              ... on PRISMIC_NavigationFooterMenu {
                primary {
                  title
                }
                fields {
                  indented
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on PRISMIC_Pricing {
                      meta_title
                      meta_description
                      _meta {
                        uid
                      }
                      _linkType
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
      allSearchs {
        edges {
          node {
            meta_title
            meta_description
            meta_keywords
          }
        }
      }
    }
  }
`

const Page = ({ data, ...rest }) => {
  const {
    meta_title,
    meta_description,
    meta_keywords,
  } = data.prismic.allSearchs.edges[0].node

  const {
    header,
    footer,
    subscription_success_message,
    subscription_error_message,
  } = data.prismic.allNavigations.edges[0].node

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        keywords={meta_keywords}
      />
      <Layout
        header={
          <Header
            showFooterHeader={true}
            navigation={<Navigation items={header} />}
          />
        }
        body={<SearchLanding />}
        footer={
          <Footer
            navigation={<Lists items={footer} />}
            newsletter={
              <Newsletter
                subscriptionSuccessMessage={subscription_success_message}
                subscriptionErrorMessage={subscription_error_message}
              />
            }
          />
        }
      />
    </>
  )
}

export default Page
