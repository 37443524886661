import React from "react"
import { InstantSearch, connectSearchBox } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import queryString from "query-string"

import Styles from "./search-landing.module.scss"
import Wrapper from "../layout/wrapper/wrapper"
import SearchHits from "./search-hits"
import TextHero from "../text-hero/text-hero"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const VirtualRefinementList = connectSearchBox(() => null)

const SearchLanding = () => {
  const windowExists = typeof window !== `undefined`
  const query = windowExists
    ? queryString.parse(window?.location?.search).query
    : null

  return (
    <>
      {windowExists && (
        <Wrapper>
          <TextHero title={"Search results"} />
          <h4>You searched for "{query}"</h4>
          <section className={Styles.searchLanding}>
            <InstantSearch
              searchState={{
                query,
              }}
              indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
              searchClient={searchClient}
            >
              <VirtualRefinementList />
              <SearchHits />
            </InstantSearch>
          </section>
        </Wrapper>
      )}
    </>
  )
}

export default SearchLanding
