import React from "react"
import { Link } from "gatsby"
import { connectHits } from "react-instantsearch-dom"
import { RichText } from "prismic-reactjs"

import Image from "../../elements/image/image"
import BlogPostMeta from "../blog-post-meta/blog-post-meta"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./search-landing.module.scss"
import queryString from "query-string"

const SearchHits = connectHits(({ hits }) => {
  const windowExists = typeof window !== `undefined`
  const previewLength = 200
  const query = windowExists
    ? queryString.parse(window?.location?.search).query
    : null

  return (
    <div>
      {hits.length ? (
        <>
          {hits.map((hit, index) => {
            const {
              image,
              imageSharp,
              author,
              publication_date,
              _meta,
              parent,
              title,
              meta_title,
              body,
            } = hit

            const isBlogPost = _meta?.type === "blog_post"
            const parentId = parent?._meta?.uid
            const parentPath = parentId ? `/${parentId}` : ""
            const slug = _meta?.uid
            const readMoreText = isBlogPost ? "Read more" : "Visit page"

            const path = isBlogPost
              ? `/blog-post/${slug}`
              : `${parentPath}/${slug}`

            return (
              <Link to={path} key={index} className={Styles.article}>
                {image && imageSharp && (
                  <div className={Styles.thumbnailWrapper}>
                    <Image sharp={imageSharp} alt={image.alt} />
                  </div>
                )}
                <div>
                  <div to={path} key={index} className={Styles.title}>
                    <h2>{title || meta_title}</h2>
                  </div>

                  {isBlogPost && (
                    <>
                      <BlogPostMeta author={author} date={publication_date} />

                      <p className={Styles.previewText}>
                        {RichText.asText(
                          body,
                          linkResolver,
                          htmlSerializer
                        ).substring(0, previewLength)}
                        ...
                      </p>
                    </>
                  )}

                  <div className={Styles.readMoreLink} to={path}>
                    {readMoreText}
                  </div>
                </div>
              </Link>
            )
          })}
        </>
      ) : (
        <h5>
          There were no results for your query of "{query}". Please try again.
        </h5>
      )}
    </div>
  )
})
export default SearchHits
