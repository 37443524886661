import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import Styles from "./blog-post-meta.module.scss"

const BlogPostMeta = props => {
  const { date, author } = props

  return (
    <div className={Styles.meta}>
      <span className={Styles.author}>{author} | </span>
      <time dateTime={date}>{dayjs(date).format("MMMM DD YYYY")}</time>
    </div>
  )
}

BlogPostMeta.propTypes = {
  date: PropTypes.string,
  author: PropTypes.string,
}

export default BlogPostMeta
