import React from "react"
import PropTypes from "prop-types"
import Title from "../../elements/title/title"
import Styles from "./text-hero.module.scss"

const TextHero = props => {
  const { title } = props

  return (
    <div className={Styles.textHeroWrapper}>
      <Title title={title} tag={"h1"} />
    </div>
  )
}

TextHero.propTypes = {
  title: PropTypes.string,
}

export default TextHero
